import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"

const Wrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  transform: scaleX(-1);
`

const Avatar = props => (
  <Wrapper {...props}>
    <Image fluid={props.image} />
  </Wrapper>
)

export default Avatar
